
import axios from 'axios';
export default {
    
    async createInsurances(params)  {
        return await axios.post(`insurances/create` , params)
    },
    async createInsurancesList(params)  {
        return await axios.post(`insurances/create/list` , params)
    },
    async updateInsurancesColumn(column , value , data)  {
        return await axios.put(`insurances/update_list?${column}=${value}` , data)
    },
    async deleteInsurancesList(list)  {
        return await axios.delete(`insurances/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportInsurances(column , value)  {
        return await axios.get(`insurances/report?${column}=${value}`)
    },
    async getAllInsurances()  {
        return await axios.get(`insurances/all`)
    },
    async getOneInsurances(insurance_id)  {
        return await axios.get(`insurances/all/${insurance_id}`)
    },
    async getInsurancesByColumn(column , value)  {
        return await axios.get(`insurances/filter?column=${column}&value=${value}`)
    },
    async deleteInsurances(insurance_id)  {
        return await axios.delete(`insurances/delete/${insurance_id}`)
    },
    async updateInsurances(insurance_id , params)  {
        return await axios.put(`insurances/update/${insurance_id}` , params)
    }
}